export const BASE_URL = process.env.REACT_APP_BASE_URL + 'api/';
export const LOGIN = "auth/login";
export const LOGOUT = "auth/logout";
export const REGISTER = "auth/register";
export const FORGOT_PASSWORD = "auth/forgot-password";
export const RESET_PASSWORD = "auth/reset-password";
export const UPDATE_PROFILE = "profile/update";
export const GET_PROFILE = "profile";
export const CHANGE_PASSWORD = "profile/changePassword";
export const SYSTEM_DATA = "system-data";
export const GET_ORDER_LIST = "order";
export const UPSERT_ORDER = "order/upsert";
export const EXPORT_ORDER = "order/export";
export const EXPORT_IMAGES = "order/download-zip";
export const DELETE_ORDER = "order/delete/";
export const GET_TRANSPORTER_LIST = "transporter";
export const UPSERT_TRANSPORTER = "transporter/upsert";
export const DELETE_TRANSPORTER = "transporter/delete/";
export const GET_SITE_LIST = "sites";
export const UPSERT_SITE = "sites/upsert";
export const DELETE_SITE = "sites/delete/";
export const GET_ACCOUNTANT_LIST = "accountant";
export const UPSERT_ACCOUNTANT = "accountant/upsert";
export const DELETE_ACCOUNTANT = "accountant/delete/";

