import Text from "../_widgets/Text/Text";
import Input from "../_widgets/Input/Input";
import noData from "../../Assets/img/noData.png";
import Button from "../_widgets/Button/Button";
import NavBar from "../_widgets/NavBar/NavBar";
import BasicModal from "../_widgets/Modal/BasicModal";
import { Row, Col, Image, Table } from "react-bootstrap";
import CustomPagination from "../_widgets/Pagination/Pagination";
import DateRangePicker from "../_widgets/DateRangePicker/DateRangePicker";
import AnimatedPage from "../AnimatedPage/AnimatedPage";
import MainLoader from "../_widgets/Loader/MainLoader";
import Dropdown from '../_widgets/Dropdown/Dropdown';
import CustomTooltip from "../_widgets/Tooltip/CustomTooltip";
import MultiSelectDropdown from "../_widgets/MultiSelect/MultiSelectDropdown";
import { formattedValue, getAccountantOptions, getMaterialOptions, getTransporterOptions, preventPlusMinusInNumberInput, } from "../../Services/common";
import { changeDateFormatForDisplay } from "../../Utils/dateUtils";
import { isNullOrEmpty } from "../../Utils/stringUtils";
import { warn } from "../../Wrapper/toast/toast";
import { MAX_PERCENTAGE_LIMIT_REACHED, MAX_WEIGTH_REACHED, maximumLengthAllowed } from "../../Constants/stringConstants";
import IconResource from "../../Wrapper/IconResource/IconResource";
import ModalImageCarousel from "./ModalImageCarousel";

const OrdersUI = ({ loading, buttonLoading, modalShow, setModalShow, handleClose, downloadImages, orderList, activePage, onPageChange, dates, setDates, datePickerStartRef, datePickerEndRef, searchQuery, setSearchQuery, orderData, setOrderData, upsertOrder, downloadExcelFile, selectedTranporterOptions, setSelectedTranporterOptions, selectedAccountantOptions, setSelectedAccountantOptions, selectedSupplierOptions, setSelectedSupplierOptions, handleChange, handleImageClick, showImagePreview, setShowImagePreview, selectedImages, setSelectedImages, }) => {

  return (
    <AnimatedPage>
      <NavBar
        // button="Add Order"
        // buttonIcon={"bag"}
        heading={`All Orders`}
        onClick={() => document.body.classList.toggle("side_active")}
        // setModalShow={setModalShow}
        Icon="menu"
      />

      <ModalImageCarousel
        modalShow={showImagePreview}
        setModalShow={setShowImagePreview}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
      />

      <BasicModal
        show={modalShow}
        size="lg"
        setModalShow={setModalShow}
        handleClose={handleClose}
        heading="Add Order"
        footer
        button2Text={"Save"}
        button2Click={upsertOrder}
        loading={buttonLoading}
      >
        <div className="patients_ui">
          <Row>
            <Col xl={12}>
              <div className="white_card text-start">
                <Row className="mb-3">
                  <Col lg={6}>
                    <Input
                      label="Date"
                      type="date"
                      placeholder="Enter Date"
                      name="date"
                      value={orderData.date || ""}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Challan No"
                      type="number"
                      placeholder="Enter Challan No"
                      name="challanNo"
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      value={orderData.challanNo || ""}
                      onChange={e => {
                        if (e.target.value.length <= 20) {
                          handleChange(e);
                        } else {
                          warn(maximumLengthAllowed(20));
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Dropdown
                      label="Material"
                      placeholder="Material"
                      name={orderData.material || ""}
                      options={getMaterialOptions()}
                      onChange={(selectedValue) => setOrderData({ ...orderData, material: selectedValue })}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Vehical No"
                      type="number"
                      placeholder="Enter Vehical No"
                      name="vehicalNo"
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      value={orderData.vehicalNo || ""}
                      onChange={e => {
                        if (e.target.value.length <= 20) {
                          handleChange(e);
                        } else {
                          warn(maximumLengthAllowed(20));
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Gross Wt (kg)"
                      type="number"
                      placeholder="Enter Gross Wt"
                      name="grossWt"
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      value={orderData.grossWt || ""}
                      onChange={e => {
                        const val = e.target.value;
                        if (val.length <= 10) {
                          if (val <= 10000) {
                            handleChange(e);
                          } else {
                            warn(MAX_WEIGTH_REACHED);
                          }
                        } else {
                          warn(maximumLengthAllowed(10));
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Percentage"
                      type="number"
                      placeholder="Enter %age"
                      name="percentage"
                      value={orderData.percentage || ""}
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      onChange={e => {
                        if (e.target.value <= 100) {
                          handleChange(e);
                        } else {
                          warn(MAX_PERCENTAGE_LIMIT_REACHED);
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Net Wt (kg)"
                      type="number"
                      placeholder="Enter Net Wt"
                      name="netWt"
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      value={orderData.netWt || ""}
                      onChange={e => {
                        const val = e.target.value;
                        if (val.length <= 10) {
                          if (val <= '10000') {
                            handleChange(e);
                          } else {
                            warn(MAX_WEIGTH_REACHED);
                          }
                        } else {
                          warn(maximumLengthAllowed(10));
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Dropdown
                      label="Transporter"
                      placeholder="Transporter"
                      name={orderData.transporter || ""}
                      options={getTransporterOptions()}
                      onChange={(selectedValue) => setOrderData({ ...orderData, transporter: selectedValue })}
                    />
                  </Col>
                  <Col lg={6}>
                    <Dropdown
                      label="Supplier/Loading Source"
                      placeholder="Supplier/Loading Source"
                      name={orderData.supplierLoadingSource || ""}
                      options={getTransporterOptions()}
                      onChange={(selectedValue) => setOrderData({ ...orderData, supplierLoadingSource: selectedValue })}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Royality"
                      type="number"
                      placeholder="Enter Royality"
                      name="royality"
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      value={orderData.royality || ""}
                      onChange={e => {
                        const val = e.target.value;
                        if (val.length <= 10) {
                          handleChange(e);
                        } else {
                          warn(maximumLengthAllowed(10));
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Company Ticket No"
                      type="number"
                      placeholder="Enter Company Ticket No"
                      name="companyTicketNo"
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      value={orderData.companyTicketNo || ""}
                      onChange={e => {
                        const val = e.target.value;
                        if (val.length <= 10) {
                          handleChange(e);
                        } else {
                          warn(maximumLengthAllowed(10));
                        }
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <Input
                      label="Images"
                      type="file"
                      placeholder="Upload Images"
                      name="images"
                      onChange={handleChange}
                      multiple
                    />
                  </Col>
                  <Col lg={12}>
                    <Input
                      label="Remarks"
                      type="text"
                      as="textarea"
                      rows={3}
                      placeholder="Enter Remarks"
                      name="remarks"
                      value={orderData.remarks || ""}
                      onChange={e => {
                        const val = e.target.value;
                        if (val.length <= 50) {
                          handleChange(e);
                        } else {
                          warn(maximumLengthAllowed(50));
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </BasicModal>

      <div className="patient_list white_card">
        <div className="top_bar">
          <div className="d-flex align-items-center gap-2">
            <Button text="Download Excel" buttonType="outline" onClick={downloadExcelFile} isLoading={buttonLoading === "excel"} />
            <Button text="Download Images" buttonType="outline" onClick={downloadImages} isLoading={buttonLoading === "image"} />
          </div>

          <Row className="search_filter">
            <Col lg={1} xs={2} sm={1}>
              <div className="bg_grey d-flex flex-column align-items-center justify-content-center position-relative">
                {selectedTranporterOptions.length > 0 ? <div className="round-notification">
                  {selectedTranporterOptions.length}
                </div> : ""}
                <CustomTooltip text="Transporters" placement='top'>
                  <MultiSelectDropdown
                    items={getTransporterOptions()}
                    name={
                      <IconResource
                        type={"filter"}
                        size={20}
                        color={"var(--primary)"}
                      />
                    }
                    selectedOptions={selectedTranporterOptions}
                    onChange={value => setSelectedTranporterOptions(value)}
                  />
                </CustomTooltip>
              </div>
            </Col>
            <Col lg={1} xs={2} sm={1}>
              <div className="bg_grey d-flex flex-column align-items-center justify-content-center position-relative">
                {selectedAccountantOptions.length > 0 ? <div className="round-notification">
                  {selectedAccountantOptions.length}
                </div> : ""}
                <CustomTooltip text="Accountants" placement='top'>
                  <MultiSelectDropdown
                    items={getAccountantOptions()}
                    name={
                      <IconResource
                        type={"filter"}
                        size={20}
                        color={"var(--primary)"}
                      />
                    }
                    selectedOptions={selectedAccountantOptions}
                    onChange={value => setSelectedAccountantOptions(value)}
                  />
                </CustomTooltip>
              </div>
            </Col>
            <Col lg={1} xs={2} sm={1}>
              <div className="bg_grey d-flex flex-column align-items-center justify-content-center position-relative">
                {selectedSupplierOptions.length > 0 ? <div className="round-notification">
                  {selectedSupplierOptions.length}
                </div> : ""}
                <CustomTooltip text="Suppliers" placement='top'>
                  <MultiSelectDropdown
                    items={getTransporterOptions()}
                    name={
                      <IconResource
                        type={"filter"}
                        size={20}
                        color={"var(--primary)"}
                      />
                    }
                    selectedOptions={selectedSupplierOptions}
                    onChange={value => setSelectedSupplierOptions(value)}
                  />
                </CustomTooltip>
              </div>
            </Col>

            <Col lg={4} xs={12} sm={4}>
              <div>
                <Input placeholder={"Search"} suffixIcon={`${searchQuery.length > 0 ? "cross" : "search"}`}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onClickIconSuff={() => setSearchQuery("")} />
              </div>
            </Col>
            <Col lg={5} sm={8}>
              <div className="date_range_picker">
                <DateRangePicker
                  startDate={dates?.startDate}
                  setStartDate={(startDate) => setDates({ ...dates, startDate })}
                  endDate={dates?.endDate}
                  setEndDate={(endDate) => setDates({ ...dates, endDate })}
                  onChange={(startDate, endDate) => setDates({ startDate, endDate })}
                  datePickerStartRef={datePickerStartRef}
                  datePickerEndRef={datePickerEndRef}
                  showDateShortcuts={true}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="custom_table position-relative">
          {
            loading ? (
              <MainLoader />
            ) : orderList?.data?.length > 0 ? (
              <>
                <div>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Date</th>
                        <th>Challan No</th>
                        <th>Material</th>
                        <th>Vehicle No</th>
                        <th>Gross Wt</th>
                        <th>%age</th>
                        <th>Net Wt</th>
                        <th>Transporter</th>
                        <th>Supplier/Loading Source</th>
                        <th>Royality</th>
                        <th>Company Ticket No</th>
                        <th>Images</th>
                        <th>Accountant</th>
                        <th>Site</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orderList?.data?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{changeDateFormatForDisplay(data.date)}</td>
                            <td>{data.challan_no}</td>
                            <td>{data.material?.name}</td>
                            <td>{data.vehicle_no}</td>
                            <td><div className="text-end">{formattedValue(data.gross_wt, false, true)}</div></td>
                            <td><div className="text-end">{formattedValue(data.deduction, false, true)}</div></td>
                            <td><div className="text-end">{formattedValue(data.net_wt, false, true)}</div></td>
                            <td>{data.transporter?.name}</td>
                            <td>{data.supplier?.name}</td>
                            <td>{data.royality}</td>
                            <td><CustomTooltip text={data.company_ticket_no}>{data.company_ticket_no.slice(0, 10)}</CustomTooltip></td>
                            <td>
                              <div className="d-flex gap-2">
                                {data.attachments?.map((url, i) =>
                                  <div key={i} style={{ width: "50px", height: "50px", overflow: "hidden", border: "1px solid var(--border)", borderRadius: "8px" }}>
                                    <Image
                                      width={"100%"}
                                      height="100%"
                                      style={{ objectFit: "cover", cursor: "pointer" }}
                                      src={process.env.REACT_APP_BASE_URL + "storage/" + url}
                                      alt={`Attachment ${i + 1}`}
                                      title={`Attachment ${i + 1}`}
                                      onClick={() => handleImageClick(data.attachments, i)}
                                    />
                                  </div>)}
                              </div>
                            </td>
                            <td>{data.accountant?.name}</td>
                            <td>{data.accountant?.site?.name}</td>
                            <td>{isNullOrEmpty(data.remark) ? '-' : data.remark}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <CustomPagination activePage={activePage}
                  onPageChange={onPageChange}
                  pageLength={orderList?.per_page}
                  totalData={orderList?.total}
                  lastPage={orderList?.data.last_page}
                />
              </>
            ) : (
              <>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Challan No</th>
                      <th>Material</th>
                      <th>Vehicle No</th>
                      <th>Gross Wt</th>
                      <th>%age</th>
                      <th>Net Wt</th>
                      <th>Transporter</th>
                      <th>Supplier/Loading Source</th>
                      <th>Royality</th>
                      <th>Company Ticket No</th>
                      <th>Images</th>
                      <th>Accountant</th>
                      <th>Site</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table>
                <div className="no_data position-relative">
                  <img
                    src={noData}
                    className="img-fluid"
                    style={{ width: "300px" }}
                  />
                  <Text type="h2" text="There is no data to display." />
                  {/* <Button buttonType="primary" text="Add Order" onClick={() => setModalShow(true)} /> */}
                </div>
              </>
            )}
        </div>
      </div>
    </AnimatedPage>
  );
};

export default OrdersUI;
